import { getContextData } from '../utils/django'
import {
	eventListenerAdd,
	executeToggleDisableOnInput,
	onclickElements,
	toggleDisableOnInput,
} from '../utils/helpers/eventHelpers'
import { alertSuccessConfirmation } from '../globals/alerts'
import ready from '../globals/ready'
import { show } from '../utils/helpers/show'
import { post } from '../api/crud'
import { successMessage } from '../globals/messages'
import { apiError } from '../api/alerts'

/**
 * Entrypoint
 */
export default async function startAppLogin() {
	setEventListeners()

	emailVerificationMessage()
	const mode = getContextData('mode')
	if (mode === 'otp') {
		document.querySelector('input[name="otp"]').focus()
	} else {
		document.querySelector('#short').focus()
	}
	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		show('#appInfo')
	}
	toggleDisableOnInput('#orgShortEmail', 'button[name="orgShortRequest"]')
	toggleDisableOnInput(
		'#shortForPwdReset, #emailPWReset',
		'button[name="passwordRequest"]'
	)

	toggleDisableOnInput(
		'#short, #username, #password, #oneTimePassword',
		'#loginbutton',
		'input',
		true
	)
}

/**
 * set all listeners for login
 */
function setEventListeners() {
	onclickElements({
		'#changeLang': changeLanguage,
		'button[name="orgShortRequest"]': () => submitOrgShortRequest(),
		'button[name="passwordRequest"]': () => submitRequestPassword(),
	})
	eventListenerAdd({ '#short': () => trimOrgShort() }, 'input')
	eventListenerAdd({ '#short': () => trimOrgShort() }, 'change')

	const loginForm = document.querySelector('#loginform')
	loginForm.addEventListener('submit', (event) => {
		trimOrgShort()

		if (document.querySelector('#oneTimePassword')) {
			document.querySelector('#loginbutton').disabled = true
		}

		let theme
		const themeDark = matchMedia('(prefers-color-scheme: dark)').matches
		if (themeDark) {
			theme = 'dark'
		} else {
			theme = 'default'
		}
		document.cookie = `preferredSkin=${theme}`
	})

	const loginButtonRequiredFields =
		'#short, #username, #password, #oneTimePassword'

	document.querySelectorAll('.shortCacheItem').forEach((entry) => {
		entry.addEventListener('click', () => {
			const short = document.querySelector('#short')
			short.value = entry.dataset.short
			short.classList.remove('is-invalid')
			executeToggleDisableOnInput(loginButtonRequiredFields, '#loginbutton')
		})
	})

	document.querySelectorAll('.emailCacheItem').forEach((entry) => {
		entry.addEventListener('click', () => {
			const username = document.querySelector('#username')
			username.value = entry.dataset.email
			username.classList.remove('is-invalid')
			executeToggleDisableOnInput(loginButtonRequiredFields, '#loginbutton')
		})
	})
}

/**
 * Submitting the org short request using the api
 */
async function submitOrgShortRequest() {
	const data = {
		email: document.querySelector('#orgShortEmail').value,
	}

	await post('forgot-short', data, false)
	successMessage({}) // Calling the successMessage even if the email was not send
	const button = document.querySelector('button[name="orgShortRequest"]')
	button.disabled = true
	setTimeout(() => {
		// 10sec spam protection (hardcoded in the backend - do NOT change this value)
		button.disabled = false
	}, 10000)
}

/**
 * Submitting the reset password request using the api
 */
async function submitRequestPassword() {
	const data = {
		email: document.querySelector('#emailPWReset').value,
		short: document.querySelector('#shortForPwdReset').value,
	}

	const response = await post('reset-password', data, false)
	if (response.status === 204) {
		successMessage({})
	} else {
		apiError(response.status, response.data)
	}
	const button = document.querySelector('button[name="passwordRequest"]')
	button.disabled = true
	setTimeout(() => {
		// 10sec spam protection (hardcoded in the backend - do NOT change this value)
		button.disabled = false
	}, 10000)
}

/**
 * Shows the message if the `success` parameter is in the page
 */
async function emailVerificationMessage() {
	const success = getContextData('success')
	if (success) {
		alertSuccessConfirmation('MLx200xEmailVerification')
	}
}

/**
 * Handles the language selection
 */
async function changeLanguage() {
	const data = {
		csrfmiddlewaretoken: document.querySelector(
			'input[name=csrfmiddlewaretoken]'
		).value,
		language: document.querySelector('#language').value,
	}
	const url = document.querySelector('#languageURL').value

	await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
	}).then((data) => {
		if (data.status === 200) {
			location.reload()
		}
	})
}

/**
 * trim whitespaces from orgShort
 */
function trimOrgShort() {
	const shortField = document.querySelector('#short')
	shortField.value = shortField.value.trim()
}

ready(startAppLogin, true)
